<template>
  <div>
    <v-card class="pa-4">
      <p class="text-2xl ma-5">
        Partner <strong>{{ selectedPartner.name }}</strong
        >&nbsp;<sub>(id:{{ selectedPartner.id }})</sub> > PartnerAccount :
        <strong>{{ name }}</strong> <sub>(id:{{ id }})</sub>
      </p>

      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12"> </v-col>
            <v-col cols="12" sm="12">
              <p>
                Department id: <strong>{{ department_id }}</strong> / Name:
                <strong>{{ name }}</strong> / Code: <strong>{{ code }}</strong>
              </p>
            </v-col>

            <v-col v-if="'identifier' in fieldsToDisplay" cols="12" sm="12">
              <v-text-field
                v-model="identifier"
                :label="fieldsToDisplay.identifier"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col v-if="'login' in fieldsToDisplay" cols="12" sm="12">
              <v-text-field
                v-model="login"
                :label="fieldsToDisplay.login"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col v-if="'email' in fieldsToDisplay" cols="12" sm="12">
              <v-text-field
                v-model="email"
                :label="fieldsToDisplay.email"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col v-if="'password' in fieldsToDisplay" cols="12" sm="12">
              <v-text-field
                v-model="password"
                :label="fieldsToDisplay.password"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col v-if="'client_id' in fieldsToDisplay" cols="12" sm="12">
              <v-text-field
                v-model="client_id"
                :label="fieldsToDisplay.client_id"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col v-if="'client_secret' in fieldsToDisplay" cols="12" sm="12">
              <p class="pre-label">{{ fieldsToDisplay.client_secret }}:</p>
              <pre class="pre-for-textarea">{{ client_secret }}</pre>
            </v-col>

            <v-col v-if="'app_id' in fieldsToDisplay" cols="12" sm="12">
              <v-text-field
                v-model="app_id"
                :label="fieldsToDisplay.app_id"
                outlined
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col v-if="'app_secret' in fieldsToDisplay" cols="12" sm="12">
              <p class="pre-label">{{ fieldsToDisplay.app_secret }}:</p>
              <pre class="pre-for-textarea">{{ app_secret }}</pre>
            </v-col>

            <v-col v-if="'api_key' in fieldsToDisplay" cols="12" sm="12">
              <p class="pre-label">{{ fieldsToDisplay.api_key }}:</p>
              <pre class="pre-for-textarea">{{ api_key }}</pre>
            </v-col>

            <v-col v-if="'api_secret' in fieldsToDisplay" cols="12" sm="12">
              <p class="pre-label">{{ fieldsToDisplay.api_secret }}:</p>
              <pre class="pre-for-textarea">{{ api_secret }}</pre>
            </v-col>

            <v-col v-if="'key_file_text' in fieldsToDisplay" cols="12" sm="12">
              <p class="pre-label">{{ fieldsToDisplay.key_file_text }}:</p>
              <pre class="pre-for-textarea">{{ key_file_text }}</pre>
            </v-col>

            <v-col
              v-if="'key_file_text_2' in fieldsToDisplay"
              cols="12"
              sm="12"
            >
              <p class="pre-label">
                {{ fieldsToDisplay.key_file_text_2 }}
                &nbsp;
                <a
                  v-show="gamByEmailConfList.length"
                  href="javascript:void(0)"
                  @click.prevent="
                    gamByEmailShowConfListAsText = !gamByEmailShowConfListAsText
                  "
                  >(changer l'affichage)</a
                >
                :
              </p>
              <div v-if="gamByEmailConfList.length">
                <div v-if="gamByEmailShowConfListAsText">
                  <pre class="pre-for-textarea">{{ key_file_text_2 }}</pre>
                </div>
                <div v-else>
                  <v-card
                    v-for="(item, index) in gamByEmailConfList"
                    :key="index"
                  >
                    <v-card-title class="my-0 py-2">
                      Report ID:&nbsp;
                      <span v-if="client_id && client_id != ''">
                        <a
                          :href="`https://admanager.google.com/${client_id}#reports/report/detail/report_id=${item.report_id}`"
                        >
                          {{ item.report_id }}
                        </a>
                      </span>
                      <span v-else>
                        {{ item.report_id }}
                      </span>

                      &nbsp;-&nbsp;
                      <b>{{ item.source_type }}</b>

                      &nbsp;

                      <v-chip
                        class="ma-2"
                        x-small
                        :class="item.is_active ? `success` : ``"
                      >
                        active: {{ item.is_active }}
                      </v-chip>
                    </v-card-title>
                    <v-card-text>
                      file_name_pattern: {{ item.file_name_pattern }}<br />
                      cols: {{ item.revenue_col }} | {{ item.impressions_col }}
                    </v-card-text>
                  </v-card>
                  <br />

                  <a
                    v-show="gamByEmailConfList.length"
                    href="javascript:void(0)"
                    @click.prevent="
                      gamByEmailShowConfListAsCode =
                        !gamByEmailShowConfListAsCode
                    "
                    >(display/close code)</a
                  >
                  :<br />
                  <pre v-show="gamByEmailShowConfListAsCode">
cd /home/reportapi/main/
source .venv/bin/activate
python -m crawlers.gam.gam_by_email_recovery 
                    <span v-for="item in gamByEmailConfList">
{{ item.source_type }} <input type="checkbox"/>
--filename="{{ item.file_name_pattern }}
--filename="RMR CA VIDEO_PREBID BY AMAZON_ReworldMedia (Mar 1, 2024 - Mar 26, 2024).csv"
--filename="RMR CA VIDEO_GOOGLE OPEN BIDDING_ReworldMedia (01-Mar-2024 - 27-Mar-2024).csv"<br />
                    </span>
                  </pre>
                </div>
              </div>
              <div v-else>
                <pre class="pre-for-textarea">{{ key_file_text_2 }}</pre>
              </div>
            </v-col>

            <v-col
              v-if="'key_file_binary' in fieldsToDisplay"
              cols="12"
              sm="12"
            >
              <!--v-model="key_file_binary"-->
              <v-file-input
                :key="inputFileFieldsComponentKeys.key_file_binary"
                show-size
                @change="onChangeFileUploadKeyFileBinay"
                :label="fieldsToDisplay.key_file_binary"
                outlined
                dense
                disabled
                truncate-length="500"
              ></v-file-input>
            </v-col>

            <v-col
              v-if="'config' in fieldsToDisplay && !gamByEmail"
              cols="12"
              sm="12"
            >
              <p class="pre-label">{{ fieldsToDisplay.config }}:</p>
              <pre class="pre-for-textarea">{{ config }}</pre>
            </v-col>

            <v-col cols="12" sm="12">
              <v-switch
                v-model="active"
                :label="`Active: ${active.toString()}`"
                outlined
                dense
                disabled
              ></v-switch>
            </v-col>

            <v-col cols="12">
              <v-btn
                :data-id="id"
                :to="`/admin/partner/account/edit/${id}`"
                color="primary"
                icon
                fab
                small
                elevation="4"
                class="mx-2"
              >
                <v-icon>
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { ref } from "@vue/composition-api";
import axios from "@axios";
import {
  mdiMinus,
  mdiPlus,
  mdiPencil,
  mdiDelete,
  mdiDotsVertical,
  mdiContentDuplicate,
} from "@mdi/js";

export default {
  name: "PartnerAccountModalView",
  props: {
    partnerAccountId: {
      type: Number,
      default: () => {
        return null;
      },
      validator(value) {
        let intValue = parseInt(value);
        return intValue == value && value >= 1;
      },
    },
  },
  async created() {
    if (this.partnerAccountId) {
      const { data } = await axios.get(
        `/partner_accounts/${this.partnerAccountId}`
      );
      this.selectedPartnerCode = data.partner_code;
      this.setSelectedPartnerCode();
      for (const key in data) {
        if (key in this) {
          this[key] = data[key];
        }
      }
      if (this.selectedPartnerCode == "gam" && this.code.includes("email")) {
        this.gamByEmail = true;
        if (
          "key_file_text_2" in this &&
          this["key_file_text_2"] &&
          this["key_file_text_2"].trim() != ""
        ) {
          this.gamByEmailConfList = JSON.parse(this["key_file_text_2"]);
        }
      }
    }
  },
  setup() {
    const isSnackbarVisible = ref(false);
    return {
      isSnackbarVisible,
    };
  },
  methods: {
    setSelectedPartnerCode() {
      this.selectedPartner = this.partners.filter(
        (partner) => partner.code === this.selectedPartnerCode
      )[0];
      this.partner_id = this.selectedPartner.id;
      this.fieldsToDisplay =
        this.fieldsToDisplayByPartner[this.selectedPartnerCode];
      for (const field of this.optionnalTextFields) {
        this[field] = "";
      }
      for (const field in this.inputFileFieldsComponentKeys) {
        this.inputFileFieldsComponentKeys[field]++;
      }
    },
  },
  data() {
    return {
      gamByEmail: false,
      gamByEmailConfList: [],
      gamByEmailShowConfListAsText: false,
      gamByEmailShowConfListAsCode: false,
      selectedPartner: {
        id: null,
        name: "",
      },
      selectedPartnerCode: null,
      partners: [
        { id: 1, code: "seedtag", name: "Seedtag" },
        { id: 2, code: "gam", name: "GAM" },
        { id: 3, code: "taboola", name: "Taboola" },
        { id: 4, code: "avantis", name: "Avantis" },
        { id: 5, code: "viously", name: "Viously" },
        { id: 6, code: "pepsia", name: "Pepsia" },
        { id: 7, code: "showheroes", name: "Showheroes" },
        { id: 8, code: "impactify", name: "Impactify" },
        { id: 9, code: "ogury", name: "Ogury" },
        { id: 10, code: "teads", name: "Teads" },
        { id: 11, code: "dailymotion", name: "Dailymotion" },
        { id: 12, code: "sublime", name: "Sublime" },
        { id: 13, code: "bliink", name: "Bliink" },
        { id: 14, code: "missena", name: "Missena" },
        { id: 15, code: "aax", name: "Aax" },
        { id: 16, code: "inskin", name: "Inskin" },
        { id: 17, code: "digiteka", name: "Digiteka" },
        { id: 18, code: "outbrain", name: "Outbrain" },
        { id: 19, code: "facebook", name: "Facebook" },
        { id: 20, code: "snapchat", name: "Snapchat" },
        { id: 21, code: "youtube", name: "Youtube" },
        { id: 22, code: "adpone", name: "Adpone" },
        { id: 23, code: "richaudience", name: "Richaudience" },
        { id: 24, code: "browsi", name: "Browsi" },
        { id: 25, code: "pubstack", name: "Pubstack" },
        { id: 26, code: "actirise", name: "Actirise" },
        { id: 45, code: "optidigital", name: "Optidigital" },
        { id: 70, code: "moneytag", name: "MoneyTag" },
        { id: 88, code: "kwords", name: "K-Words" },
        { id: 89, code: "dblock", name: "D-Block" },
      ],
      fieldsToDisplay: [],
      fieldsToDisplayByPartner: {
        seedtag: { api_key: "api_key" },
        gam: {
          email: "Email",
          client_id: "Network code",
          app_id: "Position custom dimension Id",
          key_file_text: "Authenticated credentials",
          key_file_text_2:
            "key_file_text_2 (JSON): API => Credentials / By Email => File conf",
          config: "JSON config",
        },
        viously: {
          key_file_text:
            'Credentials: csv ";" separated cols site_identifier;api_key;api_secret',
        },
        taboola: {
          identifier: "identifier",
          client_id: "client_id",
          client_secret: "client_secret",
        },
        avantis: { login: "login", password: "password" },
        pepsia: { client_id: "client_id", api_key: "api_key" },
        showheroes: { client_id: "client_id", api_key: "api_key" },
        impactify: { api_key: "api_key" },
        ogury: { api_key: "api_key" },
        teads: { email: "email", api_key: "api_key" },
        dailymotion: {
          client_id: "client_id",
          client_secret: "client_secret",
          login: "login",
          password: "password",
        },
        sublime: { api_key: "api_key", api_secret: "api_secret" },
        bliink: { login: "login", password: "password" },
        missena: { api_key: "api_key" },
        aax: { login: "login", password: "password" },
        inskin: {
          email: "email",
          key_file_text: "Azur Outlouk email app credentials (Json object)",
          key_file_text_2: "File conf (Json object)",
        },
        digiteka: { login: "login", password: "password" },
        outbrain: { login: "login", password: "password" },
        facebook: {
          app_id: "app_id",
          client_id: "user_id",
          client_secret: "client_secret",
          api_secret: "long token",
        },
        snapchat: {
          client_id: "client_id",
          client_secret: "client_secret",
          api_key: "api_key",
        },
        youtube: { client_id: "client_id", key_file_text: "key_file_text" },
        adpone: {
          api_key: "api_key",
        },
        richaudience: {
          api_key: "api_key",
        },
        browsi: { login: "login", password: "password" },
        pubstack: {
          email: "email",
          key_file_text: "Azur Outlouk email app credentials (Json object)",
          key_file_text_2: "File conf (Json object)",
        },
        actirise: {
          api_key: "token",
          key_file_text: "Liste des sites identifiers",
        },
        kwords: {
          email: "email",
          key_file_text: "Azur Outlouk email app credentials (Json object)",
          key_file_text_2: "File conf (Json object)",
        },
        moneytag: {
          email: "email",
          key_file_text: "Azur Outlouk email app credentials (Json object)",
          key_file_text_2: "File conf (Json object)",
        },
        optidigital: { login: "login", password: "password" },
      },
      mandatoryTextFields: [
        "id",
        "department_id",
        "partner_id",
        "name",
        "code",
      ],
      optionnalTextFields: [
        "identifier",
        "login",
        "email",
        "password",
        "client_id",
        "client_secret",
        "app_id",
        "app_secret",
        "api_key",
        "api_secret",
        "key_file_text",
        "key_file_text_2",
        "config",
      ],
      optionnalBoolFields: ["active"],
      inputFileFieldsComponentKeys: {
        key_file_binary: 0,
      },
      // fields
      id: null,
      department_id: null,
      partner_id: "",
      name: "",
      code: "",
      identifier: "",
      login: "",
      email: "",
      password: "",
      client_id: "",
      client_secret: "",
      app_id: "",
      app_secret: "",
      api_key: "",
      api_secret: "",
      key_file_text: "",
      key_file_binary: "",
      config: "",
      active: 0,
      defaultValues: {
        id: null,
        department_id: null,
        partner_id: "",
        name: "",
        code: "",
        identifier: "",
        login: "",
        email: "",
        password: "",
        client_id: "",
        client_secret: "",
        app_id: "",
        app_secret: "",
        api_key: "",
        api_secret: "",
        key_file_text: "",
        key_file_binary: "",
        config: "",
        active: 0,
      },
      // common elements
      departmentList: [
        { id: "", name: "" },
        // { id: 1, name: "Content to commerce", shortname: "ctc" },
        { id: 2, name: "Programmatique" },
        //{ id: 3, name: "CRM", shortname: "crm" },
      ],
      snackbarColor: "info", // info, primary, success, warning, error
      snackbarText: "",
      dialogConfirmVisible: false,
      dialogConfirmTitle: "",
      dialogConfirmText: "",
      dialogConfirmCallbackFunction: null,
      icons: {
        mdiPencil,
        mdiMinus,
        mdiPlus,
        mdiDelete,
        mdiDotsVertical,
        mdiContentDuplicate,
      },
    };
  },
  watch: {
    openDialogIsOpen(visible) {
      if (visible) {
        this.identifier = "";
        this.$refs.FormPartnerAccount.resetValidation();
      }
    },
    partnerAccountId: async function (newVal, oldVal) {
      // watch it
      if (this.partnerAccountId) {
        // reset values
        for (const key in this.defaultValues) {
          this[key] = this.defaultValues[key];
        }
        this.gamByEmail = false;
        this.gamByEmailConfList = [];
        this.gamByEmailShowConfListAsText = false;
        // fetch partner_account data
        const { data } = await axios.get(
          `/partner_accounts/${this.partnerAccountId}`
        );
        this.selectedPartnerCode = data.partner_code;
        this.setSelectedPartnerCode();
        for (const key in data) {
          if (key in this) {
            this[key] = data[key];
          }
        }
        if (this.selectedPartnerCode == "gam" && this.code.includes("email")) {
          this.gamByEmail = true;
          if (
            "key_file_text_2" in this &&
            this["key_file_text_2"] &&
            this["key_file_text_2"].trim() != ""
          ) {
            this.gamByEmailConfList = JSON.parse(this["key_file_text_2"]);
          }
        }
      }
    },
  },
  computed: {},
  mounted() {
    /*
    this.selectedPartnerCode = "seedtag";
    this.setSelectedPartnerCode();
    this.partner_id = "1";
    this.name = "Melty";
    this.code = "melty";
    this.api_key =
      "e1008d*******d0a2072c";
      */
  },
};
</script>
<style scoped>
.pre-for-textarea {
  width: 100%;
  display: block !important;
  word-break: break-all !important;
  word-wrap: break-word !important;
  overflow-wrap: anywhere !important;
  border-radius: 5px;
  border: 1px solid rgb(192, 192, 192, 0.5);
  padding: 5px;
}
</style>
